import React from 'react';

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>© 2024 LOAI</p>
    </div>
  </footer>
);

export default Footer;
