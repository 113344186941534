import React, { useEffect } from 'react';
import trashIcon from '../assets/trash.svg';

const InputSection = ({
  inputData,
  setInputData,
  handleClear,
}) => {

  useEffect(() => {
    const textarea = document.getElementById('input');
    const lineNumbers = document.querySelector('.line-numbers');

    const updateLineNumbers = () => {
      const numberOfLines = Math.max(textarea.value.split('\n').length, 22); // 최소 줄 수를 22로 설정
      lineNumbers.innerHTML = Array(numberOfLines).fill('<span></span>').join('');
      // Update the height of the textarea
      textarea.style.height = `${(numberOfLines + 1) * 21}px`; // Adjust height based on the number of lines + 1
    };

    textarea.addEventListener('input', updateLineNumbers);
    updateLineNumbers(); // Call it initially to set the initial height

    return () => {
      textarea.removeEventListener('input', updateLineNumbers);
    };
  }, [inputData]);

  return (
    <div className="column is-12-touch is-8-desktop">
      <div className="field">
        <div className="control">
          <div className="editor label">
            <label className="label">🗒️ Column Data Here 👇</label>
          </div>
        </div>
        <div className="control">
          <div className="editor" id="editor">
            <div className="line-numbers">
              <span></span>
            </div>
            <textarea
              id="input"
              name="data"
              spellCheck="false"
              autoFocus
              placeholder="Input Your Column Data 📥"
              value={inputData}
              onChange={(e) => setInputData(e.target.value)}
              style={{overFlowX : 'auto', whiteSpace : 'nowrap'}}
            ></textarea>
          </div>
          <div className="buttons has-addons is-vertical">
            <button
              className="button is-small is-transparent"
              type="button"
              aria-label="Clear"
              data-balloon-pos="left"
              onClick={() => handleClear(setInputData)}
            >
              <span className="icon">
                <img src={trashIcon} width="20" alt="trash" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputSection;
