import React from 'react';

const AboutSection = () => (
  <section className="section" id="about">
    <div className="container">
      <h2 className="title">About</h2>
      <hr />
      <div className="columns is-centered">
        <div className="column is-10-tablet is-8-desktop is-6-widescreen">
          <div className="content has-text-centered-tablet">
            <p>
              Have you ever had to separate data organized in Excel or columns with commas and wrap it in quotes?😂 <br/>It can be a tedious and challenging task. To solve this difficulty, the <a href="#" title="Comma Separator &amp; Delimiter">Comma Separator</a> was developed.
            </p>
            <p>
              This tool simplifies the process of converting and formatting data, making it easier to prepare data, create SQL queries, and handle various programming tasks. Whether dealing with Excel columns, CSV files, or other data sets, this tool can save you significant time and effort.
            </p>
            <p>
              We hope you find this tool useful and that it helps you work more efficiently. Feel free to use this website to simplify your tasks and improve your productivity.👍
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutSection;
