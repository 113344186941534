import React from 'react';
import clipboardIcon from '../assets/clipboard.svg';
import trashIcon from '../assets/trash.svg';

const ResultsSection = ({ results, handleClear, handleCopy }) => (
  <div className="column is-12-touch is-8-desktop">
    <div className="field">
      <div className="control">
        <div className="editor label">
          <label className="label">Output Data Here 💌</label>
        </div>
      </div>
      <div className="control">
        <div className="editor">
          <div className="line-numbers-empty">
            <span></span>
          </div>
          <textarea
            id="results"
            name="result-data"
            spellCheck="false"
            autoFocus
            placeholder="click 'Process' button"
            value={results}
            readOnly
          ></textarea>
        </div>
        <div className="buttons has-addons is-vertical">
          <button
            className="button is-small is-transparent"
            type="button"
            aria-label="Clear"
            data-balloon-pos="left"
            onClick={() => handleClear()}
          >
            <span className="icon">
              <img src={trashIcon} width="20" alt="trash" />
            </span>
          </button>
          <button
            className="button is-small is-transparent"
            type="button"
            aria-label="Copy"
            data-balloon-pos="left"
            onClick={() => handleCopy(results)}
          >
            <span className="icon">
              <img src={clipboardIcon} width="20" alt="copy" />
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default ResultsSection;
