import React, { useEffect } from 'react';

const Settings = ({
  quotation,
  setQuotation,
  delimiter,
  setDelimiter,
  spaceNumber,
  setSpaceNumber,
  startTag,
  setStartTag,
  endTag,
  setEndTag,
  processInputData,
}) => {

  useEffect(() => {
    if (quotation === '4') {
      setDelimiter('4'); // Set delimiter to "New Line" when "Tag" is selected
    }
  }, [quotation, setDelimiter]);

  return (
    <div className="column is-12-mobile is-2-desktop options has-text-centered-desktop">
      <div className="field">
        <div className="control">
          <label className="label">Quotation Marks</label>
        </div>
        <div className="control">
          <div className="select is-fullwidth">
            <select id="quotation" name="quotation" value={quotation} onChange={(e) => setQuotation(e.target.value)}>
              <option value="1">'&nbsp;&nbsp;&nbsp;&nbsp;'</option>
              <option value="2">"&nbsp;&nbsp;&nbsp;&nbsp;"</option>
              <option value="3">None</option>
              <option value="4">Tag</option>
            </select>
          </div>
        </div>
      </div>
      {quotation === '4' && (
        <div className="field" id="tagInput" name="tagInput">
          <div className="control">
            <label className="label">🔽Which Tag?</label>
          </div>
          <div className="control">
            <input
              id="startTag"
              className="input half"
              type="text"
              name="name"
              placeholder="Start-tag"
              value={startTag}
              onChange={(e) => setStartTag(e.target.value)}
            />
            <input
              id="endTag"
              className="input half"
              type="text"
              name="name"
              placeholder="End-tag"
              value={endTag}
              onChange={(e) => setEndTag(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="field">
        <div className="control">
          <label className="label">Delimiter</label>
        </div>
        <div className="control">
          <div className="select is-fullwidth">
            <select id="delimiter" name="delimiter" value={delimiter} onChange={(e) => setDelimiter(e.target.value)}>
              <option value="1">,</option>
              <option value="2">;</option>
              <option value="3">|</option>
              <option value="4">New Line</option>
              <option value="5">Space</option>
            </select>
          </div>
        </div>
      </div>
      {delimiter === '5' && (
        <div className="field" id="spaceInput" name="spaceInput">
          <div className="control">
            <label className="label">🔽How many?</label>
          </div>
          <div className="control">
            <input
              id="spaceNumber"
              className="input"
              type="number"
              name="name"
              placeholder="number of Space"
              value={spaceNumber}
              onChange={(e) => setSpaceNumber(e.target.value)}
            />
          </div>
        </div>
      )}
      <div className="field is-grouped">
        <div className="control">
          <label className="checkbox label" htmlFor="jsonfix">
            <div className="control">
              <button type="button" id="process" name="process" className="button is-action" onClick={processInputData}>
                Process ➡️
              </button>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default Settings;
