import React from 'react';
import logo from '../assets/comma_logo.svg';
import shareIcon from '../assets/shareIcon.svg'; // 공유하기 아이콘을 해당 경로에 추가하세요

const copyToClipboard = () => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.value = window.location.href;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  alert('URL has been copied to the clipboard.');
};

const Navbar = () => (
  <nav className="navbar is-spaced is-fixed-top" role="navigation" aria-label="main navigation">
    <div className="container">
      <div className="navbar-brand">
        <h1 className="navbar-item">
          <a className="navbar-item" href="/">
            <img src={logo} aria-label="Comma Separator &amp Delimiter" height="35px" />
          </a>
        </h1>
      </div>
      <div className="navbar-end">
        <button className="button is-action" onClick={copyToClipboard}>
          <span className="icon" >
            <img src={shareIcon} width="15s" alt="Share" />
          </span>
          <span>Share</span>
        </button>
      </div>
    </div>
  </nav>
);

export default Navbar;
