import React, { useState } from 'react';
import Navbar from './components/Navbar';
import InputSection from './components/InputSection';
import Settings from './components/Settings';
import ResultsSection from './components/ResultsSection';
import AboutSection from './components/AboutSection';
import Footer from './components/Footer';
import './App.css';

const App = () => {
  const [inputData, setInputData] = useState('');
  const [quotation, setQuotation] = useState('1');
  const [delimiter, setDelimiter] = useState('1');
  const [spaceNumber, setSpaceNumber] = useState('1');
  const [startTag, setStartTag] = useState('<li>');
  const [endTag, setEndTag] = useState('</li>');
  const [results, setResults] = useState('');

  const validateInput = () => {
    if (inputData.trim() === '') {
      alert('Please input column data');
      return false;
    } else if (quotation === '4' && (startTag.trim() === '' || endTag.trim() === '')) {
      alert('Please input Tag');
      return false;
    } else if (delimiter === '5' && spaceNumber.trim() === '') {
      alert('Please input number of space');
      return false;
    } else if (delimiter === '5' && Number(spaceNumber) < 0) {
      alert('Please input only positive numbers for number of space.');
      return false;
    }
    return true;
  };

  const processInputData = () => {
    if (validateInput()) {
      const arrText = inputData.split(/\r?\n/).filter(item => item.trim() !== '');
      let result = '';
      arrText.forEach((item, index) => {
        switch (quotation) {
          case '1':
            result += `'${item}'`;
            break;
          case '2':
            result += `"${item}"`;
            break;
          case '4':
            result += `${startTag}${item}${endTag}`;
            break;
          default:
            result += item;
        }
        switch (delimiter) {
          case '1':
            if (index !== arrText.length - 1) result += ', ';
            break;
          case '2':
            result += '; ';
            break;
          case '3':
            if (index !== arrText.length - 1) result += '|';
            break;
          case '4':
            if (index !== arrText.length - 1) result += '\n';
            break;
          case '5':
            if (index !== arrText.length - 1) result += ' '.repeat(spaceNumber);
            break;
          default:
            break;
        }
      });
      setResults(result);
    }
  };

  const handleCopy = async (text) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      try {
        await navigator.clipboard.writeText(text);
        alert('Text is copied to the clipboard.');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    } else {
      // Fallback method for browsers that do not support the Clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        alert('Text is copied to the clipboard.');
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const handleClear = (setter) => {
    setter('');
  };

  return (
    <div>
      <Navbar />
      <section className="section is-primary">
        <form className="container">
          <div className="columns is-mobile is-centered is-multiline is-vcentered">
            <InputSection
              inputData={inputData}
              setInputData={setInputData}
              handleClear={handleClear}
            />
            <Settings
              quotation={quotation}
              setQuotation={setQuotation}
              delimiter={delimiter}
              setDelimiter={setDelimiter}
              spaceNumber={spaceNumber}
              setSpaceNumber={setSpaceNumber}
              startTag={startTag}
              setStartTag={setStartTag}
              endTag={endTag}
              setEndTag={setEndTag}
              processInputData={processInputData}
            />
            <ResultsSection
              results={results}
              handleCopy={handleCopy}
              handleClear={() => handleClear(setResults)}
            />
          </div>
        </form>
      </section>
      <AboutSection />
      <Footer />
    </div>
  );
};

export default App;
